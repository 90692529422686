*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

a{
  color: #333;
  text-decoration: none;
}

.container{
  padding: 0 10rem;
  margin-top: 2em;
}