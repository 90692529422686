.navWrapper{

}
.nav{
    list-style: none;
    background: white;
    display: flex;
    -webkit-flex-flow: row wrap;
   /* border-style: none none solid none;
    border-width: 1px;*/
}

.nav img{
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.nav p{
    text-align: left;
    margin-top: 20px;
    display: block;
    color: black;
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
}

.infoContainer{
    list-style: none;
    border-radius: 0px 20px 20px 0px; 
    background: rgb(183, 196, 221);
    display: flex;
    -webkit-flex-flow: row wrap;
}

.infoContainer img{
    width: 40px;
    height: 25px;
    padding-top: .05em;
    padding-left: 1em;    
    display: block;
}

.infoContainer p{
    padding: 0 0 0 1em;

    color:black;
    font-family: 'Courier New', Courier, monospace;
}