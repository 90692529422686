.body-wrapper{
    padding-top: 3em;
}

.bodyContent {
    font-family: 'Courier New', Courier, monospace;
    font-size: 3em;
}

.bodyInformation{
    padding-top: 2em;
}

.emailContent{
    display: block;
}
.emailContent img{
    display: inline;
    width: 40px;
    height: 40px;
}
.emailContent p{
    margin: 0 0 0 .35em;
    display: inline;
    font-family: 'Courier New', Courier, monospace;
    font-size: 30px;
}

/* unvisited link */
.emailContent a:hover {
  color: red;
}

/* selected link */
.emailContent a:active {
  color: blue;
}


.linkedInContent{
    display: block;
    padding-top: 2em;
}

.linkedInContent img{
    display: inline;
    width: 40px;
    height: 40px;
}
.linkedInContent p{
    margin: 0 0 0 .35em;
    display: inline;
    font-family: 'Courier New', Courier, monospace;
    font-size: 30px;
}

/* unvisited link */
.linkedInContent a:hover {
  color: red;
}

/* selected link */
.linkedInContent a:active {
  color: blue;
}

.gitContent {
    display: block;
    padding-top: 1em;
}

.gitContent img{
    display: inline;
    width: 40px;
    height: 40px;
}
.gitContent p{
    margin: 0 0 0 .35em;
    display: inline;
    font-family: 'Courier New', Courier, monospace;
    font-size: 30px;
}

/* unvisited link */
.gitContent a:hover {
  color: red;
}

/* selected link */
.gitContent a:active {
  color: blue;
}
